<template>
	<div class="notice_popup prj_itv pass" :class="type == '1' ? 'change' : 'hr'">
		<div class="close">
			<img class="ml-0" src="/images/close.png" alt="닫기" @click="$emit('close')"/>
		</div>

		<template v-if="type == '1'">
			<div class="member"><tecSummary tagName="span" class="name" :value="targetData" :corpNm="asgCorpNm"/></div>
			<div class="tit">프로젝트 인터뷰 결과</div>
			<img class="change" src="/images/admin/pop_itv_change.png" alt="교체투입"/>
			<InputComp type="textarea" v-model="failedReason" placeholder="교체 사유를 입력해주세요!" />
			<div class="qs">위 기술인재의 인터뷰 상태를 ‘불합격 및 교체투입’으로 변경하시겠습니까?</div>
			<div class="select-btn">
				<div class="btn" @click="$emit('close')">취소</div>
				<div class="btn" @click="findTecMber()">교체 할 인재 찾기<img src="/images/admin/pop_search_icon.png" alt="찾기"/></div>
			</div>
		</template>

		<template v-else-if="type == '2'">
			<div class="tit">기술인재 교체투입</div>
			<div class="prj_name">프로젝트명 '{{this.targetData.projectNm}}'</div>
			<div class="qs"><tecSummary tagName="span" class="name" :value="targetData" :corpNm="param.targetCorpNm"/> 님을 <br/><div class="change_name"><tecSummary tagName="span" class="name" :value="changeData" /></div> 님으로 교체투입하시겠습니까?</div>
			<div class="select-btn">
				<div class="btn" @click="$emit('close')">다른인재 선택</div>
				<div class="btn" @click="tecMberChange()">네, 교체투입하겠습니다!</div>
			</div>
		</template>
	</div>
</template>

<script>
import tecSummary from "@/components/highpro/TecSummary.vue";

export default {
	props:{
		param : Object
	},
	data() {
		return {
			targetData : {},
			changeData : {},

			targetMberSeq : this.param.targetMberSeq,
			changeMberSeq : this.param.changeMberSeq,
			interviewReqSeq : this.param.interviewReqSeq,

			failedReason : this.param.failedReason || '',
			projectSeq : this.param.projectSeq,
			div : this.param.div,
			type : this.param.type || '2',
			asgCorpNm : this.param.corpNm,
		}
	},

	components : {
		tecSummary
	},

	beforeMount() {
		//console.log('param', this.param);

		this.getPrjMberInfo();
		if(this.param.changeMberSeq) this.getMemberPrjInfo(this.param.changeMberSeq);
	},

	methods : {
		getPrjMberInfo() { // 해당부분 체크
			var param = {};
			param.tecMberSeq = this.param.targetMberSeq;
			param.projectSeq = this.param.projectSeq;
			param.interviewReqSeq = this.param.interviewReqSeq;

			param.failedReason = this.param.failedReason;
			
			this.$.httpPost('/api/prj/adm/getPrjMberInfo', param)
			.then(res => {
				this.targetData = res.data;
			}).catch(this.$.httpErrorCommon);
		},

		getMemberPrjInfo(mberSeq) {
			this.$.httpPost('/api/prj/adm/getMemberPrjInfo', {tecMberSeq : mberSeq})
				.then(res => {
					this.changeData = res.data;
					// this.asgCorpNm = res.data.projectJoinCorpNm == undefined ? "소속없음" : res.data.projectJoinCorpNm;
					this.changeData.totEvalGradeCd = this.param.changeMberTotalEvalGrade;
					//console.log(this.changeData);


				}).catch(this.$.httpErrorCommon);
		},

		// 교체투입자 찾기
		findTecMber() {
			if(!this.failedReason || this.failedReason == '') {
				alert('사유을 입렵하세요.');
				return false;
			}

			var param = {};
			param.targetMberSeq = this.targetMberSeq;
			param.failedReason = this.failedReason;
			param.projectSeq = this.projectSeq;
			param.div = this.div;
			param.corpNm = this.asgCorpNm;
			
			this.$emit('close');
			this.$router.push({name:'PRJ911M02', params:param, query:param});
		},

		tecMberChange() {
			var param = {};
			param.parentInterviewReqSeq = this.targetData.interviewReqSeq;      // 인터뷰 부모 번호
			param.projectRecrFieldSeq   = this.targetData.projectRecrFieldSeq;  // 모집분야 번호
			param.joinTermStartYyyymmdd = this.targetData.joinStartYyyymmdd;    // 참여시작 연월일
			param.joinTermEndYyyymmdd   = this.targetData.joinEndYyyymmdd;      // 참여종료 연월일
			param.targetMberNm          = this.targetData.mberNm;               // 불합격 회원명
			param.targetMberSeq         = this.targetMberSeq;              // 불합격 회원번호
			param.changeMberNm          = this.changeData.mberNm;               // 교체투입 회원명
			param.changeMberSeq         = this.changeMberSeq;              // 교체투입 회원번호
			param.failedReason          = this.failedReason                     // 교체사유
			param.workingAsgSeq			= this.changeData.corpSeq;				// 프로젝트 투입시 원소속사
			//param.projectJoinAsgSeq		= this.changeData.projectJoinCorpSeq	// 프로젝트 투입시 참여소속사
			param.interviewStatusCd     = '01';                                 // 인터뷰 상태 코드

			//console.log('tecMberChange', param);
			//console.log(this.changeData);

			this.$.httpPost('/api/prj/adm/setTecMberChange', param)
				.then(() => {
					//console.log('setTecMberChange projectSeq', this.projectSeq);
					alert('교체투입이 완료되었습니다.');
					this.$emit('close');
					this.$router.push({name:'PRJ911M01', params:{chgProjectSeq:this.projectSeq}});

				}).catch(this.$.httpErrorCommon);
		}
	}
}
</script>